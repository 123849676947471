@import '~antd/dist/antd.css';

.App {
  font-family: sans-serif;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


<!-- sdfgdfsg-->




#components-layout-demo-side .logo{
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}



.site-layout .site-layout-background {
  background: #fff;
  
}

.ant-layout-sider-children {
  height: 100%;
  margin-top: 0px;
  padding-top: 0.1px;
}
.ant-layout-header {
  height: 64px;
  padding: 0 0px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
}

.aa{
  
  border: 1px solid;
  background-color: #5daefa; 
}

.site-card-border-less-wrapper {
  padding: 30px;
  background: #ececec;
}
.aaa{
  background: #ececec;
  height: 40px;
  
}
.nav-item{
  margin: 2px 10px 2px 0px;
}
.card{
  width: 22rem;
}
.card-body{
  margin-left: -43px;
  width: 22rem;
}
.contno{
  text-align: center ;
  font-size: 20px;
  margin-top: -20px;
  margin-bottom: 15px;
}

.formadmin{
 padding-left: 250px;
}

.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica'
  
  }
  .margin-top {
  margin-top: 50px;
  }
  .justify-center {
  text-align: center;
  }
  .invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
  }
 
  .invoice-box table td {
  padding: 4px;
  vertical-align: top;
  }
  .invoice-box table tr td:nth-child(2) {
  text-align: right;
  }
  .invoice-box table tr.top table td {
  padding-bottom: 20px;
  }
  .invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
  }
  .invoice-box table tr.information table td {
  padding-bottom: 4px;
  }
  .invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  }
  .invoice-box table tr.details td {
  padding-bottom: 20px;
  }
  .invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
  }
  .invoice-box table tr.item.last td {
  border-bottom: none;
  }
  .invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
  }

/*qrcode*/
  .invoice-box1 {
    max-width: 800px;
    margin: auto;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica'
    
    }
.invoice-box1 table {
  width: 100%;
  line-height: inherit;
  text-align: center;
  }
.invoice-box1 table td {
  padding: 4px;
  vertical-align: top;
  }
  .invoice-box1 table tr td:nth-child(2) {
  text-align: right;
  }
  .invoice-box1 table tr.top table td {
  padding-bottom: 20px;
  }
  .invoice-box1 table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
  }
  .invoice-box1 table tr.information table td {
  padding-bottom: 4px;
  }
  .invoice-box1 table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  }
  .invoice-box1 table tr.details td {
  padding-bottom: 20px;
  }
  .invoice-box1 table tr.item td {
  border-bottom: 1px solid #eee;
  }
  .invoice-box1 table tr.item.last td {
  border-bottom: none;
  }
  .invoice-box1 table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
  }
  .content{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content h1{
    font-size: 2rem
  }
  .content p{
    margin-top: 0;
    margin-bottom: 0.5em;
  }
  .qr{
    height: 354px;
     margin-Left: 5px;
    
  }
  .contentqr{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 10px 0;
    border-bottom: 1px solid #e6e6e6;
  }
  .contentqr1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 10px 0;
    border-bottom: 1px solid #e6e6e6;
  }
  .contentqr1 .tax{
    margin-top: 0;
    margin-bottom: 0.5em;
    margin-left: 145px;
  }
  .contentqr1 .ref{
    margin-top: 0;
    margin-bottom: 0.5em;
    margin-left: 205px;
  }
  .contentqr1 .ref1{
    margin-top: 0;
    margin-bottom: 0.5em;
    margin-right: 135px;
  }
  .contentqr1 .ref2{
    margin-top: 0;
    margin-bottom: 0.5em;
    margin-right: 110px;
  }
  .contentqr1 p{
    margin-top: 0;
    margin-bottom: 0.5em;
    margin-right: 95px;
  }

  .contentqr img{
    width: 100%;
    max-width: 149px;
    margin-bottom: 5px;
    margin-left: 150px;
  
  }
  .contentqr .imgser{
    width: 100%;
    max-width: 150px;
    height: 145px;
    margin-bottom: 5px;
    margin-Left: -11px;
  }
  .contentqr .pser{
    margin-right: 75px;
   }
  .contentqr p{
    margin-top: 0;
    margin-bottom: 0.5em;
    margin-right: 60px;
  }

/*qrcode*/

/*slip1*/
.contentslip1{
  display: flex;
  justify-content: space-between;
  align-items: right;
  margin: 10px 0 10px 0;
} 
.contentslip1 .pslip1{
margin-left: 3rem;
} 
.contentslip1 .pslip2{
  margin-left: 0px;
  } 
.contentslip1 .pslip3{
    margin-right: 5rem;
} 
/*slip1*/
/*slip2*/
.contentslip2{
  display: flex;
  justify-content: space-between;
  align-items: right;
  margin: 10px 0 10px 0;
} 
.contentslip2 .pslip1{
margin-left: 3rem;
} 
.contentslip2 .pslip2{
  margin-left: 0px;
  } 
.contentslip2 .pslip3{
    margin-right: 1rem;
} 
/*slip2*/
/*pdf*/
.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica';
  

background-image: url(''); /*ภาพพื้นหลัง*/

background-repeat: no-repeat; /*ไม่ให้ทำภาพซ้ำๆ*/

background-position: center center; /*ตำแหน่งวางรูป*/

background-attachment: fixed; /*ต้องการให้รูปอยู่กับที่*/
  }




.contentpdf{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0 0px 0;
 
}
.contentK{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px 10px 10px;
}
.contentKong{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 133px 0px 150px; 
}
.contentpdf p{
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 110px;
}
.contentpdf .ptopPdf{
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0px;
  margin-right: 50px;
}
.contentKong .ptopPdf{
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0px;
  margin-right: 50px;
}
/*pdf*/

/*-----------------------------*/
.print{
  margin-left: 45rem;
}

/*---------------------------*/




  @media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
  width: 100%;
  display: block;
  text-align: center;
  }
  .invoice-box table tr.information table td {
  width: 100%;
  display: block;
  text-align: center;
  }
  .img{
    height: 110px;
  }
  .qr{
   
      margin-Right: 0px;
  }
  .nav{
    width: auto;
  }
  .nav-item{
    margin: 2px 0px 2px 0px;
  }
}
@media only screen 
and (max-width : 812px){
.formadmin{
  margin-left: -156px;
}
.formadmin {
  padding-left: 190px;
}
.print{
  margin-left: 0rem;
}

  .containertable {
    width: 100%;
    padding-right: 7px;
    padding-left: 7px;
    margin-right: auto;
    margin-left: auto;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  font-size: 12px;
}
  .invoice-box table tr.top table td {
    min-width: 100%;
  display: block;
  text-align: center;
  }
  .invoice-box table tr.information table td {
  width: 100%;
  display: block;
  text-align: center;
  }
  .invoice-box {
    max-width: 435px;
    margin: 10px 5px 0px 5px;
    padding: 5px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    font-size: 14px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica';
  }
  .invoice-box table tr.top table td {
    padding-bottom: 2px;
}
/*qrcode*/
.invoice-box1 table tr.top table td {
  min-width: 100%;
display: block;
text-align: center;
}
.invoice-box1 table tr.information table td {
width: 100%;
display: block;
text-align: center;
}
.invoice-box1 {
  max-width: 435px;
  margin: 10px 5px 0px 5px;
  padding: 5px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  font-size: 14px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica';
}
.invoice-box1 table tr.top table td {
  padding-bottom: 2px;
}
.contentqr{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 10px 0;
  border-bottom: 1px solid #e6e6e6;
}
.contentqr1{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 10px 0;
  border-bottom: 1px solid #e6e6e6;
}
.contentqr1 .tax{
  margin-top: 0;
  margin-bottom: 0.5em;
  margin-left: 33px;
}
.contentqr1 .ref{
  margin-top: 0;
  margin-bottom: 0.5em;
  margin-left: 88px;
}
.contentqr1 .ref1{
  margin-top: 0;
  margin-bottom: 0.5em;
  margin-right: 85px;
}
.contentqr1 .ref2{
  margin-top: 0;
  margin-bottom: 0.5em;
  margin-right: 62px;
}
.contentqr1 p{
  margin-top: 0;
  margin-bottom: 0.5em;
  margin-right: 45px;
}
.contentqr img{
  width:100%; 
   max-width: 50px ;
   margin-bottom: 5px;
   margin-left: 45px;

}
.contentqr .imgser{
 width: 100%;
  max-width: 75px;
  height:50px;
  margin-bottom:5px ;
  margin-Left:-11px;
}
.contentqr .pser{
  margin-right: 27px;
 }
.contentqr p{
  margin-top: 0;
  margin-bottom: 0.5em;
  margin-right: 90px;
}
.contentqr .scb{
  margin-top: 0;
  margin-bottom: 0.5em;
  margin-right: 90px;
}
.contentqr .ktb{
  margin-top: 0;
  margin-bottom: 0.5em;
  margin-right: 110px;
}
.contentqr .tesco{
  margin-top: 0;
  margin-bottom: 0.5em;
  margin-right: 10px;
}

/*qrcode*/
.content{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content h1{
  font-size: 2rem
}
.content p{
  margin-top: 0;
  margin-bottom: 0.5em;
}
.contentimg{
  display: flex;
 
  flex-direction: column;
}
.img{

  height: 110px;
}
.qr{
  height: 354px;
   margin-Left: -6px;
  
}
.nav{
max-width: 100%;
}
.nav-item{
  margin: 4px 20px 4px 0px;
}
.card{
  width: 100%;
}
.card-body{
  margin-left: 0px;
  width: 100%;
}
.contno{
  text-align: center ;
  font-size: 35px;
  margin-top: -50px;
  margin-bottom: 20px;
}
.btn-group-lg>.btn, .btn-lg {
  padding: .5rem 1rem;
  font-size: 2.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}
/*slip1*/
.contentslip1{
  display: flex;
  justify-content: space-between;
  align-items: right;
  margin: 10px 0 10px 0;
} 
.contentslip1 .pslip1{
margin-left: 0rem;
} 
.contentslip1 .pslip2{
  margin-left: 0px;
  } 
.contentslip1 .pslip3{
    margin-right: 0rem;
} 
.modal-lg {
  max-width: 100%;
}
.btn-sm {
  padding: .2rem .4rem;
  font-size: 9px;
  line-height: 2.5;
  border-radius: .2rem;
}


/*slip1*/


}

@media only screen and (min-device-width : 768px) 
and (max-device-width : 1024px)  {
  .invoice-box table tr.top table td {
  width: 100%;
  display: block;
  text-align: center;
  }
  .invoice-box table tr.information table td {
  width: 100%;
  display: block;
  text-align: center;
  }
  .invoice-box {
    width: 748px;
    margin: 10px 0px 0px 10px;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica';
  }
  .invoice-box table tr.top table td {
    padding-bottom: 2px;
}
.img{
  height: 152px ;
}
.qr{
 height: 400px;
  margin-left: 154px;
}
.nav{
  width: 1110px;
}
.card{
  width: 49rem;
  margin-left: -192px;
}
.card-body{
  margin-left: -95px;
  width: 46rem;
}
.contno{
  text-align: center ;
  font-size: 35px;
  margin-top: -50px;
  margin-bottom: 20px;
}
}

@media only screen and (min-device-width : 1024px) 
and (max-device-width : 1366px)  {
  .invoice-box table tr.top table td {
  width: 100%;
  display: block;
  text-align: center;
  }
  .invoice-box table tr.information table td {
  width: 100%;
  display: block;
  text-align: center;
  }
  .invoice-box {
    width: 748px;
    margin: 35px 0px 0px 143px;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica';
  }
  .invoice-box table tr.top table td {
    padding-bottom: 2px;   
}
.nav{
  width: auto;
}

}
@media print {
  @page:first-child {
    size: A4;
    margin: 2.5cm;
  }
  body {
    font-size: 12pt;
    font-family: serif;
    color: black;
    height: 20px;
  }
  .header {
    display: none;
  }
}



